.navbar-navbar {
  width: 100%;
  display: flex;
  z-index: 1000;
  position: relative;
  max-width: 1200px;
  align-self: auto;
  align-items: center;
  flex-shrink: 1;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-fiveunits);
}
.navbar-branding-logo {
  box-sizing: content-box;
  color: #80FF44;
    text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em currentColor;
    font-size: 30px;
}
.navbar-nav-content {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: center;
  margin-left: auto;
  flex-direction: row;
  justify-content: flex-start;
}
.navbar-nav-links1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  border-color: transparent;
}
.navbar-link1 {
  text-decoration: none;
}
.navbar-text1 {
  color: rgba(255, 255, 255, 1);
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  font-stretch: normal;
  text-decoration: none;
}
.navbar-hamburger {
  display: none;
}
.navbar-image1 {
  width: 100px;
  object-fit: cover;
}
.navbar-mobile-menu {
  gap: var(--dl-space-space-twounits);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  padding: var(--dl-space-space-twounits);
  z-index: 100;
  position: fixed;
  transform: translateX(100%);
  transition: 0.3s;
  align-items: stretch;
  flex-direction: column;
  background-color: #fff;
}
.navbar-branding {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.navbar-image2 {
  width: 100px;
  filter: brightness(0) saturate(100%);
  object-fit: cover;
}
.navbar-container1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.navbar-icon1 {
  width: 24px;
  height: 24px;
}
.navbar-nav-links2 {
  gap: var(--dl-space-space-unit);
  color: var(--dl-color-gray-black);
  display: flex;
  flex-direction: column;
}
.navbar-text2 {
  color: rgba(255, 255, 255, 1);
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  font-stretch: normal;
  text-decoration: none;
}
.navbar-container3 {
  display: contents;
}

@media(max-width: 767px) {
  .navbar-navbar {
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .navbar-branding-logo {
    width: 80px;
  }
  .navbar-nav-links1 {
    display: none;
  }
  .navbar-get-started1 {
    display: none;
  }
  .navbar-hamburger {
    display: flex;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .navbar-image1 {
    width: 16px;
    height: 14px;
  }
  .navbar-branding {
    width: 100%;
  }
  .navbar-nav-links2 {
    margin-top: var(--dl-space-space-twounits);
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-twounits);
    justify-content: center;
  }
}
.navbar-navbar {
  /* Add your navbar styles here */
}

.navbar-branding-logo {
  cursor: pointer;
}

.nav-link,
.navbar-link1 {
  cursor: pointer;
  padding: 10px 15px; /* Adjust padding as needed */
  transition: color 0.3s ease; /* Smooth transition for color change */
}

.nav-link.active,
.navbar-link1.active,
.navbar-text1.active {
  color: #80FF44;
  text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em currentColor;
  font-weight: bold; /* Optional: Make it bold */
}

.get-started {
  /* Styles for the get started button */
}

.navbar-mobile-menu {
  /* Styles for mobile menu */
}

/* Add additional styles for your navbar layout as necessary */

