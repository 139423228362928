.group-chat-container {
    display: flex;
    height: 100vh;
    max-height: 800px;
  }
  
  .chat-sidebar {
    width: 25%;
    background-color: #f0f0f0;
    padding: 10px;
    border-right: 1px solid #ddd;
  }
  
  .chat-sidebar h3 {
    margin-top: 0;
  }
  
  .chat-sidebar ul {
    list-style-type: none;
    padding: 0;
  }
  
  .chat-sidebar li {
    padding: 5px;
    border-bottom: 1px solid #ddd;
  }
  
  .chat-window {
    width: 75%;
    display: flex;
    flex-direction: column;
  }
  
  .messages {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    background-color: #fff;
  }
  
  .message {
    margin-bottom: 10px;
  }
  
  .message strong {
    color: #333;
  }
  
  .message-form {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ddd;
    background-color: #f9f9f9;
  }
  
  .message-form input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .message-form button {
    margin-left: 10px;
    padding: 10px 15px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .message-form button:hover {
    background-color: #0056b3;
  }
  
  .community-images {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .community-image {
    width: 300px;
    height: auto;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .community-image:hover {
    transform: scale(1.05);
  }

  .cm-img{
    width: 35vh;
  }

  .community-item {
    text-align: center; /* Center the text and image */
    margin: 20px; /* Add margin around the item */
    border: 1px solid white; /* Border color for inputs */
    border-radius: 2%;
    padding-bottom: 20px;
  }
  .msg-new{
    color: #80FF44 !important;
    text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em currentColor;
  }

  .cm-img {
    width: 300px; /* Set the width of the image */
    height: auto; /* Maintain the aspect ratio */
    cursor: pointer; /* Add a pointer cursor for better UX */
  }
  
  .home-caption1 {
    margin-top: 10px; /* Add space between the image and the heading */
    font-size: 1.5rem; /* Adjust the font size as needed */
  }

  .grp-list{
display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 70vh; /* Adjust as needed */
  border: 1px solid #ddd; /* Optional */
  }
  .type-space{
    border-radius: 20%;
  }

  .chat-sidebar{
    /* display: flex;
    flex-direction: column; */
    /* width: 100%; */
     /* Use full width of the container */
     background-color: rgba(217, 217, 217, 0) !important;
    color: #80FF44;
    text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em currentColor;
    border: 0.5px solid black;
    border-width: 20px;
    text-align: center;
    /* background-color: ; */
  }
  .gm-text{
    color: White !important;
  }
 
  .chat-window {
    /* background-color: black; */
    color: black;
    padding: 20px;
    border-radius: 10px;
    width: 70vh;
    height: 70vh;
    border: 1px solid white;
  }
  
  .message {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #80FF44;; /* Adds a border around each message */
    border-radius: 5px;
    color: white !important;
  }
  
  .message strong {
    color: #80FF44;
    text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em currentColor;
    text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em currentColor;
  }
  
  .message-form {
    display: flex;
    align-items: center;
    margin-top: 20px;
    text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em currentColor;
    background-color: rgba(217, 217, 217, 0) !important;
  }
  
  .home-input-field.type-space {
    flex: 1;
    padding: 10px;
    border-radius: 5px;
    border: none;
    
  }
  
  .home-buy4.button {
    background-color: green;
    color: white;
    padding: 10px 20px;
    border-radius: 39px;
    cursor: pointer;
    margin-left: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 60px;
  }
  
  .messages{
    background-color: rgba(217, 217, 217, 0);
  }